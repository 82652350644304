import Cookies from "services/cookies";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";

import routesConstants from "routes/routesConstants";
import LocalStorage from "services/localStorage";
import { guestLogin, logout } from "modules/login/features/loginSlice";
import { SoundHouseFooterIcon } from "constants/images";

const Header = () => {
  // state
  const [open, setOpen] = useState(false);
  // router
  const location = useLocation();
  const navigate = useNavigate();
  // react redux
  const dispatch = useDispatch();
  const { isAuth, user } = useSelector(state => state.auth);

  const handleGuestLogout = () => {
    // guest logout
    Cookies.clear();
    dispatch(guestLogin());
  };

  const handleLogout = () => {
    // user logout
    setOpen(false);
    Cookies.clear();
    LocalStorage.clear();
    dispatch(logout());
    navigate(routesConstants.LOGIN);
  };

  return (
    <>
      <div id="header" className="lg:hidden absolute top-0 left-0 w-full h-14 bg-vgraysuperdark z-50 flex items-center justify-center">
        <div className="lg:order-2 w-full absolute left-35px">
          <button
            onClick={() => setOpen(!open)}
            data-collapse-toggle="mobile-menu-2"
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm  rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none  dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <IoMdMenu className="text-2xl text-gray-200 " />
          </button>
        </div>
        <Link
          to={routesConstants.HOME}
          className="flex items-center justify-center z-50"
        >
          <img
            src={SoundHouseFooterIcon}
            alt="SoundHouseMusic"
            className="w-48 object-cover"
            loading="lazy"
          />
        </Link>
      </div>
      <div
        className={`hidden w-full lg:flex absolute bg-vgraysuperdark top-0 left-0 z-50 h-14 items-center px-10 lg:order-1 ${
          isAuth && user && location.pathname !== "/" ? "pl-24" : ""
        }`}
        id="mobile-menu-2"
      >
        <div className="flex-grow ">
          <Link
            to={routesConstants.HOME}
            aria-current="page"
            className="router-link-exact-active router-link-active"
          >
            <img
              src={SoundHouseFooterIcon}
              alt="SoundHouseMusic"
              className="w-56 object-cover"
              loading="lazy"
            />
          </Link>
        </div>
        <div className="bg-vgraysuperdark flex-shrink ml-auto md:flex space-x-10 items-center">
          <Link to={routesConstants.DISCOVER} className="text-white">
            Discover
          </Link>
          <Link to={routesConstants.BATTLEGROUND} className="text-white">
            Battleground
          </Link>
          <Link to={routesConstants.LEADER_BOARD} className="text-white mr-6">
            Hall of Fame
          </Link>
          <Link to={routesConstants.SPONSOR} className="text-white mr-6">
            Sponsor
          </Link>
          {isAuth && user ? (
            <Link to={routesConstants.PROFILE} className="text-white mr-6">
              Your Profile
            </Link>
          ) : user ? (
            <div className="text-white mr-6">Guest</div>
          ) : (
            <Link to={routesConstants.LOGIN} className="text-white mr-6">
              Login
            </Link>
          )}
          {isAuth && user ? (
            <div
              onClick={() => handleLogout()}
              className="ml-6 flex-shrink text-gray-300 header-medium text-xs hover:text-white cursor-pointer"
            >
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path>
              </svg>
            </div>
          ) : user ? (
            <div
              onClick={() => handleGuestLogout()}
              className="ml-6 flex-shrink text-gray-400 header-medium text-xs hover:text-white cursor-pointer"
            >
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path>
              </svg>
            </div>
          ) : (
            <Link
              to={routesConstants.SIGN_UP}
              className="bg-vorange hover:bg-vorangedark text-white px-4 rounded-full cursor-pointer h-12 flex items-center justify-center"
            >
              Create an Account?
            </Link>
          )}
          <div className="ml-6 flex-shrink text-white header-medium text-sm hover:text-white cursor-pointer"></div>
        </div>
      </div>
      {open && (
        <div
          className=" w-full md:flex absolute bg-vgraysuperdark top-14 left-0 z-50 items-center px-10 transition-width"
          id="mobile-menu-2"
        >
          <div className="bg-vgraysuperdark  flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
            <Link
              onClick={() => setOpen(false)}
              to={routesConstants.DISCOVER}
              className="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
            >
              Discover
            </Link>
            <Link
              onClick={() => setOpen(false)}
              to={routesConstants.BATTLEGROUND}
              className="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
            >
              Battleground
            </Link>
            <Link
              onClick={() => setOpen(false)}
              to={routesConstants.LEADER_BOARD}
              className=" block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
            >
              Hall of Fame
            </Link>
            <Link
              onClick={() => setOpen(false)}
              to={routesConstants.SPONSOR}
              className=" block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
            >
              Sponsor
            </Link>
            {isAuth && user ? (
              <>
                <Link
                  onClick={() => setOpen(false)}
                  to={routesConstants.SETTINGS}
                  className=" block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                >
                  Settings
                </Link>
                {user?.admin && (
                  <Link
                    onClick={() => setOpen(false)}
                    to={routesConstants.BATTLES_LISTING}
                    className=" block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                  >
                    Admin Settings
                  </Link>
                )}
                <Link
                  onClick={() => setOpen(false)}
                  to={routesConstants.PROFILE}
                  className=" block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                >
                  Your Profile
                </Link>
              </>
            ) : user ? (
              <div className=" block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white">
                Guest
              </div>
            ) : (
              <Link
                onClick={() => setOpen(false)}
                to={routesConstants.LOGIN}
                className=" block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
              >
                Login
              </Link>
            )}
            {isAuth && user ? (
              <Link
                onClick={() => handleLogout()}
                to={routesConstants.SIGN_UP}
                className="mb-10 bg-vorange hover:bg-vorangedark text-white px-4 rounded-full cursor-pointer h-12 flex items-center justify-center"
              >
                Logout
              </Link>
            ) : user ? (
              <Link
                onClick={() => handleGuestLogout()}
                to={routesConstants.SIGN_UP}
                className="mb-10 bg-vorange hover:bg-vorangedark text-white px-4 rounded-full cursor-pointer h-12 flex items-center justify-center"
              >
                Logout
              </Link>
            ) : (
              <Link
                onClick={() => setOpen(false)}
                to={routesConstants.SIGN_UP}
                className="mb-10 bg-vorange hover:bg-vorangedark text-white px-4 rounded-full cursor-pointer h-12 flex items-center justify-center"
              >
                Create an Account?
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Header;
