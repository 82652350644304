import lazy from "utils/lazy";

export const HomePage = lazy(
  () => import(/* webpackChunkName: "HomePage" */ "modules/home/pages"),
);
export const DiscoverPage = lazy(
  () => import(/* webpackChunkName: "DiscoverPage" */ "modules/discover/pages"),
);
export const BattleGroundPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BattleGroundPage" */ "modules/battleground/pages"
    ),
);
export const LeaderBoardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LeaderBoardPage" */ "modules/leaderBoard/pages"
    ),
);
export const LoginPage = lazy(
  () => import(/* webpackChunkName: "LoginPage" */ "modules/login/pages"),
);
export const SignUpPage = lazy(
  () => import(/* webpackChunkName: "SignUpPage" */ "modules/signup/pages"),
);
export const ProfilePage = lazy(
  () => import(/* webpackChunkName: "ProfilePage" */ "modules/profile/pages"),
);
export const SettingsPage = lazy(
  () => import(/* webpackChunkName: "SettingsPage" */ "modules/settings/pages"),
);
export const BillingPage = lazy(
  () => import(/* webpackChunkName: "BillingPage" */ "modules/billing/pages"),
);
export const BattlePage = lazy(
  () => import(/* webpackChunkName: "BattlePage" */ "modules/battle/pages"),
);
export const BattleDiscoverPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BattleDiscoverPage" */ "modules/battle/pages/BattleDiscover"
    ),
);
export const ForgottenPasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ForgottenPasswordPage" */ "modules/forgottenPassword/pages"
    ),
);
export const TermsAndConditionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TermsAndConditionPage" */ "modules/termsAndCondition/pages"
    ),
);
export const PrivacyPolicyPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PrivacyPolicyPage" */ "modules/privacyPolicy/pages"
    ),
);
export const BattlesListingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BattlesListingPage" */ "modules/adminUserRole/pages/BattlesListing"
    ),
);

export const ArtistsListingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ArtistsListingPage" */ "modules/adminUserRole/pages/ArtistsListings"
    ),
);

export const BattlesCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "BattlesCreatePage" */ "modules/adminUserRole/pages/BattlesCreate"
    ),
);
export const BattleEntrantsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BattleEntrantsPage" */ "modules/adminUserRole/pages/BattleEntrants"
    ),
);
export const ArtistEntrantsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ArtistEntrantsPage" */ "modules/adminUserRole/pages/ArtistsEntrants"
    ),
);
export const DashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardPage" */ "modules/adminUserRole/pages/Dashboard"
    ),
);
export const SponsorPage = lazy(
  () => import(/* webpackChunkName: "SponsorPage" */ "modules/sponsor/pages"),
);
