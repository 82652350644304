import { Outlet } from "react-router-dom";
import {
  ArtistEntrantsPage,
  ArtistsListingPage,
  BattleDiscoverPage,
  BattleEntrantsPage,
  BattleGroundPage,
  BattlePage,
  BattlesCreatePage,
  BattlesListingPage,
  BillingPage,
  DashboardPage,
  DiscoverPage,
  ForgottenPasswordPage,
  HomePage,
  LeaderBoardPage,
  LoginPage,
  PrivacyPolicyPage,
  ProfilePage,
  SettingsPage,
  SignUpPage,
  SponsorPage,
  TermsAndConditionPage,
} from "./routeImports";
import routesConstants from "./routesConstants";

const routesConfig = {
  private: [
    {
      path: routesConstants.SETTINGS,
      Component: SettingsPage,
    },
    {
      path: routesConstants.BILLING,
      Component: BillingPage,
    },
    {
      path: routesConstants.ARTISTS_LISTING,
      Component: ArtistsListingPage,
    },
    {
      path: routesConstants.BATTLES_LISTING,
      Component: BattlesListingPage,
    },
    {
      path: routesConstants.BATTLES_CREATE,
      Component: BattlesCreatePage,
    },
    {
      path: routesConstants.BATTLES_EDIT,
      Component: Outlet,
      children: [
        { index: true, Component: BattlesListingPage },
        { path: ":battleId", Component: BattlesCreatePage },
      ],
    },
    {
      path: routesConstants.BATTLES_ENTRANTS,
      Component: Outlet,
      children: [
        { index: true, Component: BattlesListingPage },
        { path: ":battleId", Component: BattleEntrantsPage },
      ],
    },
    {
      path: routesConstants.ARTISTS_ENTRANTS,
      Component: Outlet,
      children: [
        { index: true, Component: ArtistsListingPage },
        { path: ":artistId", Component: ArtistEntrantsPage },
      ],
    },
    {
      path: routesConstants.DASHBOARD,
      Component: DashboardPage,
    },
  ],
  public: [
    {
      path: routesConstants.LOGIN,
      Component: LoginPage,
    },
    {
      path: routesConstants.SIGN_UP,
      Component: SignUpPage,
    },
    {
      path: routesConstants.FORGOTTEN_PASSWORD,
      Component: ForgottenPasswordPage,
    },
  ],
  common: [
    {
      index: true,
      Component: HomePage,
    },
    {
      path: routesConstants.DISCOVER,
      Component: DiscoverPage,
    },
    {
      path: routesConstants.BATTLEGROUND,
      Component: BattleGroundPage,
    },
    {
      path: routesConstants.LEADER_BOARD,
      Component: LeaderBoardPage,
    },
    {
      path: routesConstants.BATTLE,
      Component: Outlet,
      children: [
        { index: true, Component: BattlePage },
        { path: ":battleId", Component: BattleDiscoverPage },
      ],
    },
    {
      path: routesConstants.TERMS_AND_CONDITION,
      Component: TermsAndConditionPage,
    },
    {
      path: routesConstants.PRIVACY_POLICY,
      Component: PrivacyPolicyPage,
    },
    {
      path: routesConstants.PROFILE,
      Component: Outlet,
      children: [
        { index: true, Component: ProfilePage },
        { path: ":slug", Component: ProfilePage },
      ],
    },
    {
      path: routesConstants.SPONSOR,
      Component: SponsorPage,
    },
  ],
};

export default routesConfig;
