import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import WaveSurfer from "wavesurfer.js";

import { BattleImage, coverArt } from "constants/images";
import {
  closePlayerModal,
  getPlayerDetails,
} from "modules/battleground/features/battleGroundSlice";
import {
  // getLikesDetails,
  likeAuthorDetails,
  updateBattleLikes,
  updateListenCount,
  updateSongLikes,
} from "modules/discover/features/discoverSlice";
import routesConstants from "routes/routesConstants";
import defaultInitials from "utils/getInitials";
import VoterModal from "./VoterModal";
import { updateXPDetails } from "modules/adminUserRole/features/adminUserSlice";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from " firebase";
import { Collection } from "constants/Collection";
import { updateGuest } from "modules/login/features/loginSlice";
import { handleClickButton } from "utils/TikTokEvents";

const Player = () => {
  const [imageError, setImageError] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [waveSurfer, setWaveSurfer] = useState(null);
  const [registerVoter, setRegisterVoter] = useState(false);
  const [disablePlayButton, setDisablePlayButton] = useState(true);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [showPopup, setShowPopup] = useState(false);
  const [battleImageError, setBattleImageError] = useState(false);

  const { isAuth, user, guestUser } = useSelector(state => state.auth);
  const { player, playList, playModal } = useSelector(
    state => state.battleGround,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const parentDiv = document.getElementById("waveform");
    while (parentDiv.firstChild) {
      parentDiv.removeChild(parentDiv.firstChild);
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 0.6);
    gradient.addColorStop(0, "#656666"); // Top color
    gradient.addColorStop((canvas.height * 0.7) / canvas.height, "#656666"); // Top color
    gradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, "#ffffff"); // White line
    gradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, "#ffffff"); // White line
    gradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, "#B1B1B1"); // Bottom color
    gradient.addColorStop(1, "#B1B1B1"); // Bottom color
    // Define the progress gradient
    const progressGradient = ctx.createLinearGradient(
      0,
      0,
      0,
      canvas.height * 0.6,
    );
    progressGradient.addColorStop(0, "#EE772F"); // Top color
    progressGradient.addColorStop(
      (canvas.height * 0.7) / canvas.height,
      "#EB4926",
    ); // Top color
    progressGradient.addColorStop(
      (canvas.height * 0.7 + 1) / canvas.height,
      "#ffffff",
    ); // White line
    progressGradient.addColorStop(
      (canvas.height * 0.7 + 2) / canvas.height,
      "#ffffff",
    ); // White line
    progressGradient.addColorStop(
      (canvas.height * 0.7 + 3) / canvas.height,
      "#F6B094",
    ); // Bottom color
    progressGradient.addColorStop(1, "#F6B094"); // Bottom color

    setWaveSurfer(
      WaveSurfer.create({
        container: "#waveform",
        waveColor: gradient,
        progressColor: progressGradient,
        barWidth: 2,
        height: 50,
      }),
    );
  }, [player?.song]);

  const earnPoints = () => {
    if (user?.id !== player?.user?.id) {
      let payload1 = {
        doc: user?.id,
        incrementValue: 2000,
      };
      dispatch(updateXPDetails(payload1));
      let payload = {
        doc: player?.user?.id,
        incrementValue: 2000,
      };
      dispatch(updateXPDetails(payload));
      let payload2 = {
        songDoc: player?.song?.id,
        userDoc: user?.id || null,
      };
      dispatch(updateListenCount(payload2));
    }
  };
  // let userInteracted = false;

  // document.addEventListener("click", async () => {
  //   console.log("user Intereaction");
  //   if (!userInteracted) {
  //     userInteracted = true;
  //     if (
  //       playModal &&
  //       !waveSurfer.isPlaying() &&
  //       !waveSurfer.getCurrentTime()
  //     ) {
  //       try {
  //         await waveSurfer.play();
  //         // setShowPopup(false); // Hide the popup if needed
  //       } catch (e) {
  //         console.log("Playback failed on user interaction:", e);
  //       }
  //     }
  //   }
  // });

  useEffect(() => {
    if (waveSurfer && player?.song?.song) {
      let totalDuration = 0;
      // eslint-disable-next-line
      let userPaused = false;
      let canEarnPoint = true;
      let playbackStartTime;
      // eslint-disable-next-line
      let playbackEndTime;
      let totalPlaybackDuration = 0;

      waveSurfer.load(player?.song?.song);
      waveSurfer.on("loading", () => {
        setLoading(true);
      });
      waveSurfer.on("ready", async () => {
        setDisablePlayButton(false);
        setLoading(false);
        try {
          playModal && (await waveSurfer.play());
        } catch (e) {
          console.log(e);
        }
        // if (!waveSurfer.isPlaying()) {
        //   setShowPopup(true);
        // }
        // document.addEventListener("click", async () => {
        //   if (!waveSurfer.isPlaying() && !waveSurfer.getCurrentTime()) {
        //     try {
        //       await waveSurfer.play();
        //       // setShowPopup(false);
        //     } catch (e) {
        //       console.log("Playback failed on user interaction:", e);
        //     }
        //   }
        // });
      });

      waveSurfer.on("play", (currentTime, e) => {
        setIsPlaying(true);
        userPaused = false;
        totalDuration = waveSurfer.getDuration();
        handleClickButton(player?.song?.id, "product", player?.song?.title);
        playbackStartTime = new Date();
      });

      waveSurfer.on("pause", currentTime => {
        setIsPlaying(false);
        userPaused = true;
        playbackEndTime = currentTime;
      });

      waveSurfer.on("error", error => {
        console.error("WaveSurfer error:", error);
      });

      waveSurfer.on("audioprocess", currentTime => {
        const twoThirdsPoint = (1 / 4) * totalDuration;
        const playbackDuration =
          (new Date().getTime() - playbackStartTime.getTime()) / 1000;
        totalPlaybackDuration = playbackDuration;
        if (
          !userPaused &&
          canEarnPoint &&
          totalPlaybackDuration >= twoThirdsPoint
        ) {
          earnPoints();
          userPaused = true;
          canEarnPoint = false;
          setTimeout(() => {
            canEarnPoint = true;
          }, 5000);
        }
      });

      waveSurfer.on("finish", () => {
        userPaused = true;
        nextSong();
      });
    }
    if (waveSurfer !== null) {
      return () => {
        waveSurfer.destroy();
      };
    }
    if (waveSurfer instanceof WaveSurfer) {
      return () => {
        waveSurfer.destroy();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveSurfer]);

  const togglePlayPause = () => {
    if (player?.song?.song) {
      waveSurfer.playPause();
      setIsPlaying(!isPlaying);
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(
        doc(db, Collection.USERS, user?.id, Collection.LIKES, player?.song?.id),
        doc => {
          if (doc.data()) {
            setIsLiked(true);
          } else {
            setIsLiked(false);
          }
        },
      );

      // Cleanup the listener when the component unmounts
      return () => unsubscribe();
    } else if (guestUser) {
      const unsubscribe = onSnapshot(
        doc(db, Collection.GUEST_USERS, guestUser?.id),
        doc => {
          let data = doc.data();

          let isExist = data?.likedSongs?.includes(player?.song?.id);
          if (isExist) {
            setIsLiked(true);
          } else {
            setIsLiked(false);
          }
        },
      );

      // Cleanup the listener when the component unmounts
      return () => unsubscribe();
    }
    // eslint-disable-next-line
  }, []);

  const nextSong = () => {
    setDisablePlayButton(true);
    // if (!disable) {
    setIsPlaying(false);
    let currentIndex = -1;

    let songListArray = playList
      ? [...playList?.filter(item => item?.song)]
      : [];

    if (songListArray?.length > 0) {
      songListArray
        ?.filter(item => item?.song)
        ?.forEach((i, index) => {
          if (i.id === player?.song?.id) {
            if (index + 1 === songListArray?.length) currentIndex = 0;
            else currentIndex = index + 1;
          }
        });

      let payload = {
        song: songListArray[currentIndex],
        play: true,
      };
      dispatch(getPlayerDetails(payload));
    }
    // }
  };

  const previousSong = () => {
    setDisablePlayButton(true);
    // if (!disable) {
    setIsPlaying(false);
    let currentIndex = -1;
    let songListArray = playList
      ? [...playList?.filter(item => item?.song)]
      : [];
    if (songListArray?.length > 0) {
      songListArray?.forEach((i, index) => {
        if (i.id === player?.song?.id) {
          if (index === 0) {
            // If it's the first song, loop back to the last song in the playlist
            currentIndex = songListArray?.length - 1;
          } else {
            // Otherwise, go back one index for the previous song
            currentIndex = index - 1;
          }
        }
      });
      let payload = {
        song: songListArray[currentIndex],
        play: true,
      };
      dispatch(getPlayerDetails(payload));
    }
    // }
  };
  const handleClosePlayerModal = () => {
    dispatch(closePlayerModal());
  };

  const likeAuthor = songId => {
    let battleId = player?.song?.battleId ? player?.song?.battleId : "";
    let payload = {
      parentDoc: user?.id,
      doc: songId,
      body: {
        battleId: battleId,
        submissionId: songId,
      },
    };
    dispatch(likeAuthorDetails(payload))
      .then(res => {
        //refreshData();
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  const unlikeAuthor = songId => {
    let payload = {
      parentDoc: user?.id,
      doc: songId,
    };
    dispatch(updateSongLikes(payload))
      .then(res => {
        //refreshData();
      })
      .catch(errors => {
        console.log(errors.message);
      });
  };

  const updateLikesInSongs = (battleId, songId, val) => {
    let payload = {
      battleId: battleId,
      submissionId: songId,
      incrementValue: val,
      voterId: user?.id,
    };
    dispatch(updateBattleLikes(payload)).then(res => {
      if (+val > 0) {
        likeAuthor(songId);
      } else {
        unlikeAuthor(songId);
      }
    });
  };

  const updateGuestDetails = songId => {
    let guestDetails = guestUser;
    let likedSongsArray = guestDetails?.likedSongs
      ? [...guestDetails?.likedSongs]
      : [];
    if (!likedSongsArray?.includes(songId)) {
      likedSongsArray.push(songId);
    }
    let payload = {
      doc: guestUser?.id,
      body: {
        ...guestUser,
        likedSongs: likedSongsArray,
      },
    };
    dispatch(updateGuest(payload)).then(res => {
      //refreshData();
    });
  };

  const updateGuestLikesInSongs = (battleId, songId, val) => {
    let payload = {
      battleId: battleId,
      submissionId: songId,
      incrementValue: val,
      voterId: guestUser?.id,
    };
    dispatch(updateBattleLikes(payload)).then(res => {
      updateGuestDetails(songId);
    });
  };

  const vote = () => {
    handleClickButton(player?.song?.id, "product", "vote");
    let isVote = isLiked;
    if (user) {
      setIsLiked(!isLiked);
      let val = isVote ? -1 : 1;
      updateLikesInSongs(player?.song?.battleId, player?.song?.id, val);
    } else if (guestUser) {
      setIsLiked(!isLiked);
      let val = isVote ? -1 : 1;
      updateGuestLikesInSongs(player?.song?.battleId, player?.song?.id, val);
    } else {
      setRegisterVoter(true);
    }
  };
  // eslint-disable-next-line
  const PopupForAllAutoPlay = () => {
    return (
      <div
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex  items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white  rounded-lg shadow dark:bg-gray-700 w-1/4">
              <button
                onClick={() => setShowPopup(false)}
                type="button"
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="popup-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <svg
                  className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h3 className="mb-3 text-sm font-normal text-gray-500 dark:text-gray-400">
                  This website uses audio for a better experience. Allow
                  auto-play?
                </h3>
                <button
                  onClick={() => togglePlayPause()}
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
                >
                  Yes, Allow
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div style={{ zIndex: 1000 }} className={`w-full  sm:block absolute `}>
      <div
        className={`fixed bottom-0 left-0 h-15 sm:h-20 w-full ${
          isAuth && user && "lg:pl-20"
        }`}
        style={{ zIndex: "100" }}
      >
        {/* {showPopup && <PopupForAllAutoPlay />} */}
        {!disablePlayButton && (
          <div
            onClick={() => handleClosePlayerModal()}
            className="absolute right-1 top-0 text-white cursor-pointer rounded p-2 border-gray-700"
          >
            x
          </div>
        )}
        <div className="absolute right-10 top-6 md:hidden ">
          <div
            onClick={() => !isLiked && vote()}
            // className={`w-full sm:w-auto cursor-pointer hover:border-vorange hover:bg-vorange hover:text-white px-3 sm:rounded-full text-sm h-8 flex items-center justify-center bg-vgraydark border border-gray-700 text-gray-400 ${isLiked && "bg-vorange text-white"
            //   }`}
            className={`w-24 cursor-pointer border-vorange bg-vorange text-white px-4 rounded-full text-sm h-8 flex items-center justify-center  md:border   ${isLiked && "opacity-25"}`}
          >
            {isLiked ? (
              <></>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className=" mr-1 feather feather-thumbs-up"
              >
                <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
              </svg>
            )}
            <span className="block lg:hidden">
              {isLiked ? "Voted" : "Vote"}
            </span>
          </div>
        </div>
        {/* main content */}
        <div className="w-full bg-vgraysuperdark h-full flex px-3 sm:px-10">
          <div className="w-full flex sm:flex-nowrap flex-wrap items-center h-full">
            <div className="flex-grow flex flex-wrap text-white">
              <div className="border-r border-vgraylight hidden sm:flex lg:w-2/12   group cursor-pointer text-white items-center overflow-hidden">
                <Link
                  state={{ slug: player?.user?.id }}
                  to={"/" + routesConstants.PROFILE + "/" + player?.user?.slug}
                  className="hidden md:block"
                >
                  <div className="w-full flex space-x-2">
                    <div className="flex xl:w-1/4">
                      {player?.user?.avatar ? (
                        <img
                          src={player?.user?.avatar}
                          alt="user-icon"
                          className="flex h-auto w-14 object-cover rounded-lg aspect-square"
                          loading="lazy"
                        />
                      ) : (
                        <Avatar
                          name={player?.user?.displayName}
                          className="rounded-lg object-cover cursor-pointer border-2 border-transparent hover:bg-vgraydark h-14 w-14"
                          email={player?.user?.email}
                          size="40"
                          maxInitials={2}
                          initials={defaultInitials(player?.user?.displayName)}
                          textSizeRatio={1}
                        />
                      )}
                    </div>
                    <div className=" lg:w-1/2 xl:w-3/4 flex items-center ">
                      <div className="w-full text-gray-200">
                        <h2 className="hidden lg:block truncate w-full text-sm font-bold ml-2 mb-1 capitalize">
                          {player?.user?.displayName}
                        </h2>
                        <span className="hidden lg:flex text-gray-500 text-xs group-hover:text-white cursor-pointer bg-vgraylight rounded-full px-1  justify-center xl:mx-2 ">
                          View Profile
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="-mt-12 sm:-mt- w-full sm:w-2/12 ml-4 flex flex-wrap  items-center justify-center">
                <div className="flex items-center justify-center">
                  <div
                    onClick={() => (disablePlayButton ? {} : previousSong())}
                    className="w-14 h-14 rounded-full flex items-center justify-center bg-vgraysuperdark"
                  >
                    <i
                      className={`text-xl ${
                        disablePlayButton
                          ? "text-gray-600"
                          : "hover:text-white text-gray-100 cursor-pointer"
                      }   fa fa-solid fa-backward`}
                      aria-hidden="true"
                    ></i>
                  </div>

                  <div
                    onClick={() => (disablePlayButton ? {} : togglePlayPause())}
                    className="w-20 h-20 rounded-full flex items-center justify-center bg-vgraysuperdark"
                  >
                    <i
                      className={`text-4xl  fa fa-solid ${
                        isPlaying ? "fa-pause-circle" : "fa-play-circle"
                      }  ${
                        disablePlayButton
                          ? "text-gray-600"
                          : "text-gray-100 hover:text-white cursor-pointer"
                      } `}
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div
                    onClick={() => (disablePlayButton ? {} : nextSong())}
                    className="w-14 h-14 rounded-full flex items-center justify-center bg-vgraysuperdark"
                  >
                    <i
                      className={`text-xl ${
                        disablePlayButton
                          ? "text-gray-600"
                          : "text-gray-100 hover:text-white cursor-pointer "
                      } fa fa-solid fa-forward`}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="flex w-full h-12  space-x-2 -mt-4 md:justify-center items-center">
                  <img
                    src={
                      imageError || !player?.song?.coverArt
                        ? coverArt
                        : player?.song?.coverArt
                    }
                    className="w-8 h-8 object-cover rounded-lg"
                    alt=""
                    onError={() => {
                      setImageError(true);
                    }}
                    loading="lazy"
                  />
                  <h3 className="truncate  whitespace-nowrap text-xs text-white">
                    {player?.song?.title}
                  </h3>
                </div>
              </div>
              <div className="md:h-10 text-white w-full md:w-4/12 px-4 pt-2 sm:w-4/12">
                {loading && (
                  <label className="text-sm text-gray-500">Loading..</label>
                )}
                <div id="waveform"></div>
              </div>
              <div className="hidden lg:w-22 sm:flex items-center mt-10 sm:mt-0">
                <div
                  onClick={() => !isLiked && vote()}
                  // className={`w-full sm:w-auto cursor-pointer hover:border-vorange hover:bg-vorange hover:text-white px-3 sm:rounded-full text-sm h-8 flex items-center justify-center bg-vgraydark border border-gray-700 text-gray-400 ${isLiked && "bg-vorange text-white"
                  //   }`}
                  className={`w-24 cursor-pointer border-vorange bg-vorange text-white px-4 rounded-full text-sm h-8 flex items-center justify-center  md:border   ${isLiked && "opacity-25"}`}
                >
                  {isLiked ? (
                    <></>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className=" mr-1 feather feather-thumbs-up"
                    >
                      <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
                    </svg>
                  )}
                  <span className="hidden md:block">
                    {isLiked ? "Voted" : "Vote"}
                  </span>
                </div>
              </div>
              {player?.battle && (
                <div className="hidden sm:block border-l border-vgraylight w-1/12 pl-2 pt-3 group cursor-pointer text-white">
                  <Link
                    state={{ battleId: player?.battle?.slug }}
                    to={
                      "/" + routesConstants.BATTLE + "/" + player?.battle?.slug
                    }
                    aria-current="page"
                    className="router-link-exact-active router-link-active"
                  >
                    <div className="w-full flex space-x-2">
                      <img
                        src={
                          battleImageError || !player?.battle?.images?.square
                            ? BattleImage
                            : player?.battle?.images?.square
                        }
                        alt={player?.battle?.title}
                        className="w-14 h-14 object-cover rounded-lg"
                        onError={() => {
                          setBattleImageError(true);
                        }}
                        loading="lazy"
                      />

                      <div className="flex-grow flex items-center">
                        <div className="w-full text-gray-200">
                          <h2 className="truncate w-36 text-sm font-bold">
                            {player?.battle?.title}
                          </h2>
                          <span className="text-gray-500 text-xs group-hover:text-white cursor-pointer bg-vgraylight rounded-full px-3">
                            View Battle
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {registerVoter && (
        <VoterModal closeModal={setRegisterVoter} vote={vote} />
      )}
    </div>
  );
};
export default Player;
