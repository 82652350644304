import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from " firebase";
import { onSnapshot, serverTimestamp } from "firebase/firestore";
import {
  updateDoc,
  increment,
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  query,
  orderBy,
  startAt,
  limit,
  getDocs,
  deleteDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Collection } from "constants/Collection";
import { handleSendMail } from "utils/handleSendMail";
import { emailConstants } from "constants/EmailConstants";
import { sendMail } from "modules/login/features/loginSlice";
import { updateXPDetails } from "modules/adminUserRole/features/adminUserSlice";
import { createPlayList } from "modules/battleground/features/battleGroundSlice";

const initialState = {
  loading: false,
  categoryList: [],
  songsList: [],
  tagSongsList: [],
  filteredSongsList: [],
};

//getting list of artists
export const getArtistList = createAsyncThunk(
  "discover/artistList",
  async (request, api) => {
    try {
      const docRef = doc(db, Collection.USERS, request.doc);
      const docSnap = await getDoc(docRef);
      let obj = docSnap.data();
      obj["id"] = docSnap.id;
      return obj;
    } catch (e) {
      console.log(e);
    }
  },
);

//getting all docs
export const getCategoryList = createAsyncThunk(
  "discover/getCategoryList",
  async (request, api) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.CATEGORIES),
        orderBy(request?.orderBy),
        startAt(request?.startAt),
        limit(request?.limit),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);
//getting new songs list
// export const getSongsList = createAsyncThunk(
//   "discover/getSongsList",
//   async (request, { dispatch }) => {
//     try {
//       let arr = [];
//       const q = query(
//         collection(db, Collection.SONGS),
//         orderBy("created", "desc"),
//       );
//       const querySnapshot = await getDocs(q);
//       querySnapshot.forEach(doc => {
//         let obj = doc.data();
//         obj["id"] = doc.id;
//         arr.push(obj);
//       });
//       let payload1 = {
//         songList: arr,
//       };
//       dispatch(createPlayList(payload1));
//       return arr;
//     } catch (e) {
//       console.log(e);
//     }
//   },
// );

export const getSongsList = createAsyncThunk(
  "discover/getSongsList",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.SONGS),
        orderBy("created", "desc"),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // Fetch initial data
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        arr = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getSongsList.fulfilled(arr));
      });
      // Dispatch action with the new data
      const payload1 = {
        songList: arr,
      };
      dispatch(createPlayList(payload1));

      return arr;
    } catch (error) {
      console.error("Error fetching songs list:", error);
      // Reject the Promise with the error
    }
  },
);

export const getSongByTag = createAsyncThunk(
  "discover/getSongByTag",
  async (payload, api) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.SONGS),
        where("tags", "array-contains", payload.tag),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

//get song by tag
export const getComments = createAsyncThunk(
  "discover/getComments",
  async (payload, api) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.SONGS, payload.songId, "comments"),
        orderBy("created", "asc"),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

// add comment
export const addComment = createAsyncThunk(
  "discover/addComment",
  async (payload, api) => {
    try {
      const docRef = await addDoc(
        collection(
          db,
          Collection.SONGS,
          payload.parentDocument,
          Collection.COMMENTS,
        ),
        { ...payload.body, created: serverTimestamp() },
      );
      payload["id"] = docRef.id;
      return payload;
    } catch (e) {
      console.log(e);
    }
  },
);
// delete comment
export const deleteComment = createAsyncThunk(
  "discover/deleteComment",
  async (payload, api) => {
    try {
      await deleteDoc(
        doc(
          db,
          Collection.SONGS,
          payload.parentDoc,
          Collection.COMMENTS,
          payload.doc,
        ),
      );
      return payload;
    } catch (e) {
      console.log(e);
    }
  },
);

export const updatePositions = createAsyncThunk(
  "discover/updatePositions",
  async (payload, { dispatch }) => {
    try {
      const docRef = doc(db, Collection.BATTLES, payload?.battleId);
      const docSnap = await getDoc(docRef);

      let data = docSnap?.data();
      const currentDate = new Date();
      let endDate = new Date(data?.endDate?.seconds * 1000);

      if (endDate > currentDate) {
        let songArray = [];
        const q = query(
          collection(db, Collection.SONGS),
          where("battleId", "==", payload?.battleId),
          orderBy("likes", "desc"),
          limit(50),
        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(doc => {
          let obj = doc.data();
          obj["id"] = doc.id;
          songArray.push(obj);
        });

        songArray?.map(async (song, index) => {
          const currentSongPosition = index + 1;
          const songDocRef = doc(db, Collection.SONGS, song?.id);
          const songDocSnap = await getDoc(songDocRef);
          const songDoc = songDocSnap?.data();
          const previousSongPosition = parseInt(songDoc?.rank);
          await updateDoc(songDocRef, {
            rank: currentSongPosition,
          });
          if (currentSongPosition !== previousSongPosition) {
            if (currentSongPosition <= 3) {
              const artistRef = doc(db, Collection.USERS, song?.userId);
              const artistDocSnap = await getDoc(artistRef);
              const artistDoc = artistDocSnap?.data();
              let to = [];
              let b = {
                email: artistDoc.email,
                name: artistDoc?.displayName,
              };
              to.push(b);
              let position =
                currentSongPosition === 1
                  ? "st"
                  : currentSongPosition === 2
                    ? "nd"
                    : "rd";
              emailConstants.SONG_RANK_EMAIL = {
                ...emailConstants.SONG_RANK_EMAIL,
                SUBJECT: `Congratulations! You're in ${currentSongPosition}${position} Place in the Music Battle!`,
                PARAGRAPH1: `We are excited to inform you that your song, ${song?.title} is currently in ${currentSongPosition}${position} place in our ongoing music battle! 🎉`,
              };
              let htmlVar = `<p>Congratulations! You're in <strong>${currentSongPosition}${position}</strong> Place in the Music Battle!</p>
              <p>
              We are excited to inform you that your song, <strong>${song?.title}</strong> is currently in <strong>${currentSongPosition}${position}</strong> place in our ongoing music battle! 🎉
              </p>
              <p>${emailConstants?.SONG_RANK_EMAIL?.PARAGRAPH3}</p>
              <p>
              ${emailConstants?.SONG_RANK_EMAIL?.WISHES}
              </p>
              `;
              let emailPayload = handleSendMail(
                to,
                emailConstants.SONG_RANK_EMAIL,
                htmlVar,
              );
              dispatch(sendMail(emailPayload));
            }
          }
        });
      }
    } catch (e) {}
  },
);

// update battle likes
export const updateBattleLikes = createAsyncThunk(
  "discover/updateBattleLikes",
  async (payload, { dispatch }) => {
    try {
      const sub = doc(db, Collection.SONGS, payload.submissionId);
      await updateDoc(sub, {
        likes: increment(payload.incrementValue),
      });
      // fetching song details
      const docRef = doc(db, Collection.SONGS, payload.submissionId);
      const docSnap = await getDoc(docRef);
      let song = docSnap.data();
      // fetching user details
      const docRef1 = doc(db, Collection.USERS, song?.userId);
      const docSnap1 = await getDoc(docRef1);
      let user = docSnap1.data();
      // send mail section
      if (payload.incrementValue > 0) {
        // setting email payload
        let to = [];
        let b = { email: user?.email, name: user?.displayName };
        to.push(b);
        let emailPayload = handleSendMail(to, emailConstants.VOTE_EMAIL);
        // dispatch action
        // dispatch(sendMail(emailPayload));
        payload?.battleId && dispatch(updatePositions(payload));
      }
      if (payload?.voterId !== docSnap1.id) {
        let xp = payload.incrementValue > 0 ? 1000 : -1000;
        let payload1 = { doc: docSnap1.id, incrementValue: xp };
        dispatch(updateXPDetails(payload1));
        let payload2 = { doc: payload?.voterId, incrementValue: xp };
        dispatch(updateXPDetails(payload2));
      }
    } catch (e) {
      console.log(e);
    }
  },
);

// update battle likes
export const updateSongLikes = createAsyncThunk(
  "discover/updateSongLikes",
  async (payload, api) => {
    try {
      await deleteDoc(
        doc(
          db,
          Collection.USERS,
          payload.parentDoc,
          Collection.LIKES,
          payload.doc,
        ),
      );
    } catch (e) {
      console.log(e);
    }
  },
);
export const updateListenCount = createAsyncThunk(
  "discover/listenCount",
  async payload => {
    try {
      const sub = doc(db, Collection.SONGS, payload.songDoc);
      await updateDoc(sub, {
        listens: increment(1),
      });
      await addDoc(
        collection(db, Collection.SONGS, payload.songDoc, Collection.LISTENS),
        {
          ...payload,
          created: serverTimestamp(),
        },
      );
    } catch (e) {
      console.log(e);
    }
  },
);
// like author
export const likeAuthorDetails = createAsyncThunk(
  "discover/likeAuthorDetails",
  async payload => {
    try {
      await setDoc(
        doc(
          db,
          Collection.USERS,
          payload.parentDoc,
          Collection.LIKES,
          payload.doc,
        ),
        {
          ...payload.body,
          created: serverTimestamp(),
        },
      );
    } catch (e) {
      console.log(e);
    }
  },
);

// get likes count
export const getLikesDetails = createAsyncThunk(
  "discover/getLikesDetails",
  async (payload, api) => {
    try {
      const docRef = doc(
        db,
        Collection.USERS,
        payload.parentDoc,
        Collection.LIKES,
        payload.doc,
      );
      const docSnap = await getDoc(docRef);
      let obj = docSnap.data();
      return obj;
    } catch (e) {
      console.log(e);
    }
  },
);

export const DiscoverSlice = createSlice({
  name: "Session",
  initialState,
  reducers: {
    filterSongs: (state, { payload }) => {
      let arr = [...state.filteredSongsList];
      let newArray = [];
      payload?.selectedList?.length > 0
        ? arr?.map(
            item =>
              (item?.tags ? item.tags : []).some(element =>
                payload.selectedList.includes(element),
              ) && newArray.push(item),
          )
        : (newArray = [...state.filteredSongsList]);
      state.songsList = newArray;
    },
  },
  extraReducers: builder => {
    // getAllDocs
    builder.addCase(getCategoryList.pending, state => {
      state.loading = true;
      state.categoryList = [];
    });
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.loading = false;
      state.categoryList = action.payload;
    });
    builder.addCase(getCategoryList.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    // getSongsList
    builder.addCase(getSongsList.pending, state => {
      state.loading = true;
    });
    builder.addCase(getSongsList.fulfilled, (state, action) => {
      state.loading = false;
      state.songsList = action.payload;
      state.filteredSongsList = action.payload;
    });
    builder.addCase(getSongsList.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    // getSongsByTag
    builder.addCase(getSongByTag.pending, state => {
      state.loading = true;
    });
    builder.addCase(getSongByTag.fulfilled, (state, action) => {
      if (state.tagSongsList.length > 0) {
        state.tagSongsList = [...state.songsList, ...action.payload];
      } else {
        state.tagSongsList = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(getSongByTag.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
  },
});
export const { filterSongs } = DiscoverSlice.actions;
export default DiscoverSlice.reducer;
